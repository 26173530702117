import Lottie from "lottie-react";

const Table = ({ colSpan = 6 }) => (
    <tr className="divide-x divide-gray-200">
        <td colSpan={colSpan} className="whitespace-nowrap p-1 text-sm text-gray-500 ">
            <div className='flex flex-col mb-10 items-center justify-center'>
                <div className='w-56'>
                    <Lottie animationData={require('Asset/Lottie/empty.json')} loop={true} />
                </div>
                <h1 class="text-5xl font-bold">Tidak Ada Data</h1>
                <p class="py-6">Tidak ada data pada filter ini. Coba ganti bulan atau hapus beberapa filter yang diterapkan.</p>
            </div>
        </td>
    </tr>
)

export default { Table }