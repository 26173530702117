import { requestGet } from "Common/Provider/RestClient"
import { useQuery } from "react-query"


export function useDetailMyCredential({ onSuccess, onError, enabled = true }) {
    const data = useQuery('/authentication/credential/my-credential', () => requestGet('/authentication/credential/my-credential'), {
        enabled: enabled,
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null,
    })
    return data
}