import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAuth } from 'App';
import { Badge } from '@tremor/react';

const possibleColors = ['amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue']

export default function ProfileEducationSubRoute() {
    const { user } = useAuth()
    const employee = user.role.employee.roleEntity

    return (
        <div className="mt-10 mx-3 pb-36">
            <DataTable value={employee?.educations} tableStyle={{ minWidth: '50rem' }}>
                <Column />
                <Column
                    field="educationTier.name"
                    header="Tingkat"
                    body={(data, options) => (
                        <span>
                            <Badge color={possibleColors.at(data.educationTier.ID % possibleColors.length)}>{data.educationTier.name}</Badge>
                        </span>
                    )}
                />
                <Column field="schoolName" header="Nama Sekolah" />
                <Column field="graduationYear" header="Tahun Kelulusan" />
                <Column field="referenceNumber" header="Nomor" />
            </DataTable>
        </div>
    )
}