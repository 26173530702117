import { Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "../../Component/Icon";
import classNames from "classnames";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";
import { useAuth } from "App";

export default function MainLayout({ children }) {
    const navigate = useNavigate()
    const { user, signOut } = useAuth()
    const confirm = useConfirmDialogStore(state => state.confirm)
    return (
        <div>
            <div className="hidden md:block">
                <div className="fixed w-24 right-0 top-0 bottom-0 left-0 bg-indigo-950">
                    <div className="flex flex-col w-full relative">
                        <NavLink to="/profile" className={({ isActive }) => classNames("w-full pt-5 pb-5 group hover:bg-gray-200", isActive ? "bg-gray-50" : null)}>
                            {({ isActive }) => (
                                <div className={`flex items-center flex-col `}>
                                    {isActive ?
                                        <Icon.IconPhospor.IdentificationBadge weight="fill" className="w-10 h-10 text-indigo-950" /> :
                                        <Icon.IconPhospor.IdentificationBadge className="w-10 h-10 text-gray-50" />
                                    }
                                    <span className={classNames("btm-nav-label", isActive ? "" : "text-gray-50")}>Profil</span>
                                </div>
                            )}
                        </NavLink>
                        <NavLink to="/attendance" className={({ isActive }) => classNames("w-full pt-5 pb-5 group hover:bg-gray-200", isActive ? "bg-gray-50" : null)}>
                            {({ isActive }) => (
                                <div className={`flex items-center flex-col `}>
                                    {isActive ?
                                        <Icon.IconPhospor.CalendarCheck weight="fill" className="w-10 h-10 text-indigo-950" /> :
                                        <Icon.IconPhospor.CalendarCheck className="w-10 h-10 text-gray-50 group-hover:text-indigo-950" />
                                    }
                                    <span className={classNames("btm-nav-label group-hover:text-indigo-950", isActive ? "" : "text-gray-50")}>Kalender</span>
                                </div>
                            )}
                        </NavLink>
                        <NavLink to="/reservation" className={({ isActive }) => classNames("w-full pt-5 pb-5 group hover:bg-gray-200", isActive ? "bg-gray-50" : null)}>
                            {({ isActive }) => (
                                <div className={`flex items-center flex-col `}>
                                    {isActive ?
                                        <Icon.IconPhospor.Users weight="fill" className="w-10 h-10 text-indigo-950" /> :
                                        <Icon.IconPhospor.Users className="w-10 h-10 text-gray-50 group-hover:text-indigo-950" />
                                    }
                                    <span className={classNames("btm-nav-label group-hover:text-indigo-950", isActive ? "" : "text-gray-50")}>Audiensi</span>
                                </div>
                            )}
                        </NavLink>
                    </div>
                    <div className="absolute bottom-5 w-full">
                        <button
                            onClick={() => confirm({
                                title: 'Keluar dari Sistem',
                                messsage: `Apakah anda yakin akan keluar dari sistem sebagai ${user.role.employee.roleEntity.name} (${user.email}) ?`,
                                onConfirm: () => {
                                    signOut()
                                    navigate('/')
                                }
                            })}
                            className={classNames("w-full pt-5 pb-5 group hover:bg-gray-200")}
                        >
                            <div className={`flex items-center flex-col `}>
                                <Icon.IconPhospor.SignOut weight="fill" className="w-10 h-10 text-white group-hover:text-indigo-950" />
                                <span className={classNames("btm-nav-label", "text-gray-50 group-hover:text-indigo-950")}>Keluar</span>
                            </div>
                        </button>
                    </div>
                </div>

            </div>
            <main className="ml-0 md:ml-24">
                {children}
            </main>

            <div className="md:hidden btm-nav drop-shadow-2xl">
                <NavLink to="/" end>
                    {({ isActive }) => (
                        <Fragment>
                            {isActive ?
                                <Icon.IconPhospor.IdentificationBadge weight="fill" className="w-5 h-5" /> :
                                <Icon.IconPhospor.IdentificationBadge className="w-5 h-5" />
                            }
                            <span className="btm-nav-label">Beranda</span>
                        </Fragment>
                    )}
                </NavLink>
                <NavLink to="/attendance">
                    {({ isActive }) => (
                        <Fragment>
                            {isActive ?
                                <Icon.IconPhospor.CalendarCheck weight="fill" className="w-5 h-5" /> :
                                <Icon.IconPhospor.CalendarCheck className="w-5 h-5" />
                            }
                            <span className="btm-nav-label">Kalender</span>
                        </Fragment>
                    )}
                </NavLink>
                <NavLink to={"/reservation"}>
                    {({ isActive }) => (
                        <Fragment>
                            {isActive ?
                                <Icon.IconPhospor.Users weight="fill" className="w-5 h-5" /> :
                                <Icon.IconPhospor.Users className="w-5 h-5" />
                            }
                            <span className="btm-nav-label">Audiensi</span>
                        </Fragment>
                    )}
                </NavLink>
                <button
                    onClick={() => confirm({
                        title: 'Keluar dari Sistem',
                        messsage: `Apakah anda yakin akan keluar dari sistem sebagai ${user.role.employee.roleEntity.name} (${user.email}) ?`,
                        onConfirm: () => {
                            signOut()
                            navigate('/')
                        }
                    })}
                >
                    <Fragment>
                        <Icon.IconPhospor.SignOut className="w-5 h-5" />
                        <span className="btm-nav-label">Keluar</span>
                    </Fragment>
                </button>
            </div>
        </div>
    )
}