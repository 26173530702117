import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAuth } from 'App';
import { Badge } from '@tremor/react';

const possibleColors = ['amber',  'cyan', 'blue']

export default function ProfileCourseSubRoute() {
    const { user } = useAuth()
    const employee = user.role.employee.roleEntity

    return (
        <div className="mt-10 mx-3 pb-36">
            <DataTable value={employee?.courses} tableStyle={{ minWidth: '50rem' }}>
                <Column />
                <Column
                    field="type"
                    header="Tipe"
                    body={(data, options) => (
                        <span>
                            <Badge color={possibleColors.at(data.type % possibleColors.length)}>{data.typeLabel}</Badge>
                        </span>
                    )}
                />
                <Column field="name" header="Nama" />
                <Column field="year" header="Tahun Penerimaan" />
                <Column field="numberReference" header="Nomor" />
            </DataTable>
        </div>
    )
}