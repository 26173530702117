import { Card, Col, Flex, Grid, Metric, Text, Icon as IconWrapper, Badge } from "@tremor/react"
import { useAuth } from "App"
import Icon from "Common/UI/Component/Icon"
import moment from "moment"

export default function ProfileInformationSubRoute() {
    const { user } = useAuth()
    const employee = user.role.employee.roleEntity

    return (
        <div className="mt-10 mx-3 pb-36">
            <Grid numCols={1} numColsSm={2} numColsLg={4} className="gap-2 hidden lg:grid" >
                <Col numColSpan={1} numColSpanLg={2}>
                    <Card decoration="top" decorationColor="white">
                        <Text>Nama Lengkap</Text>
                        <Metric>{employee.name}</Metric>
                    </Card>
                </Col>
                <div className="col-span-1 lg:col-span-2 xl:col-span-1">
                    <Card decoration="top" decorationColor={employee.NIK ? "lime" : "gray"}>
                        <Flex justifyContent="start" className="space-x-4">
                            <IconWrapper
                                icon={Icon.IconPhospor.IdentificationCard}
                                variant="light"
                                size="xl"
                                color={employee.NIK ? "lime" : "gray"}
                            />
                            <div className="truncate">
                                <Text>NIK (Nomor Induk Karyawan)</Text>
                                <Metric className="truncate">{employee.NIK}</Metric>
                            </div>
                        </Flex>
                    </Card>
                </div>
                <div className="col-span-1 lg:col-span-2 xl:col-span-1">
                    <Card decoration="top" decorationColor={employee.NPWP ? "lime" : "gray"}>
                        <Flex justifyContent="start" className="space-x-4">
                            <IconWrapper
                                icon={Icon.IconPhospor.IdentificationCard}
                                variant="light"
                                size="xl"
                                color={employee.NPWP ? "lime" : "gray"}
                            />
                            <div className="truncate">
                                <Text>NPWP (Nomor Pokok Wajib Pajak)</Text>
                                <Metric className="truncate">{employee.NPWP}</Metric>
                            </div>
                        </Flex>
                    </Card>
                </div>
                <div className="col-span-1 lg:col-span-2 xl:col-span-1">
                    <Card decoration="top" decorationColor="white">
                        <Text>Surel</Text>
                        <Metric>{employee.email}</Metric>
                    </Card>
                </div>
                <Card decoration="top" decorationColor="white">
                    <Text>Telepon</Text>
                    <Metric>
                        {employee.phoneNumber}
                    </Metric>
                </Card>
                <Card decoration="top" decorationColor="white">
                    <Text>Kelamin</Text>
                    <Metric>{employee.genderLabel}</Metric>
                </Card>
                <Card decoration="top" decorationColor="white">
                    <Text>Jenis</Text>
                    <Metric>{employee.employeeType}</Metric>
                </Card>
            </Grid>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-1.5">
                <div>
                    <div className="bg-white shadow sm:rounded-lg mt-4">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Informasi Karyawan
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi terkait akses dan akun ke platform Mitras DUDI</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">ID Unik</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{employee.ID}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Nama Lengkap</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{employee.name}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Telepon</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.phoneNumber}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Surel</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.email}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">NIK</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.NIK || '-'}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">NPWP</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.NPWP || '-'}
                                    </dd>
                                </div>
                            </dl>
                        </div>

                    </div>
                </div>
                <div>
                    <div className="bg-white shadow sm:rounded-lg mt-4">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Informasi Akun
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi terkait akses dan akun ke platform Mitras DUDI</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">ID Unik</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{user.ID}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Surel</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{user.email}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Tanggal Pembuatan</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {moment(user.created, 'YYYY-MM-DD HH:mm:ss').format('dddd, D MMMM Y')}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Jam Pembuatan</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {moment(user.created, 'YYYY-MM-DD HH:mm:ss').format('H:mm:ss')}
                                    </dd>
                                </div>
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Hak Akses</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                                <div className="w-0 flex-1 flex items-center">
                                                    <Icon.IconPhospor.Suitcase className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    <span className="ml-2 flex-1 w-0 truncate">
                                                        Karyawan
                                                    </span>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    <Badge icon={user.role.employee ? Icon.IconPhospor.LockKeyOpen : Icon.IconPhospor.LockKey} color={user.role.employee ? "lime" : "gray"}>
                                                        {user.role.employee ? 'Memiliki' : 'Tidak Memiliki'}
                                                    </Badge>
                                                </div>
                                            </li>
                                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                                <div className="w-0 flex-1 flex items-center">
                                                    <Icon.IconPhospor.Crown className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    <span className="ml-2 flex-1 w-0 truncate">
                                                        Super Administrator
                                                    </span>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    <Badge icon={user.role.superAdministrator ? Icon.IconPhospor.LockKeyOpen : Icon.IconPhospor.LockKey} color={user.role.superAdministrator ? "lime" : "gray"}>
                                                        {user.role.superAdministrator ? 'Memiliki' : 'Tidak Memiliki'}
                                                    </Badge>
                                                </div>
                                            </li>
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div>
                            <a
                                href="#"
                                className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                            >
                                Read full application
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}