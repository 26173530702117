import { Metric, Subtitle } from "@tremor/react";
import { useAuth } from "App";
import Avatar from "Common/UI/Component/Avatar";
import Icon from "Common/UI/Component/Icon";
import MainLayout from "Common/UI/Layout/Main/MainLayout";
import ProfileInformationSubRoute from "./ProfileInformationSubRoute";
import { TabViewRoutes } from "Common/UI/Block/TabViewRoutes";
import ProfileEducationSubRoute from "./ProfileEducationSubRoute";
import ProfileCourseSubRoute from "./ProfileCourseSubRoute";


export default function ProfileRoute() {
    const { user } = useAuth()
    return (
        <MainLayout>
            <div className="flex flex-col flex-1">
                <div className="flex flex-col flex-1 items-center mt-5 gap-3">
                    <Avatar.Single image={user.avatarURL} shape="circle" className="!w-[14rem] !h-[14rem] drop-shadow-md" />
                    <div className="w-full relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex gap-1.5 justify-center lg:justify-end lg:mr-7">
                            <a
                                href="https://absen.mitrasdudi.id/profile/edit"
                                className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <Icon.IconPhospor.IdentificationBadge className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span>Ubah Profil</span>
                            </a>
                            <a
                                href="https://absen.mitrasdudi.id/profile/edit"
                                className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <Icon.IconPhospor.Password className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span>Ubah Password</span>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <Metric>
                            {user.role.employee.roleEntity.name}
                        </Metric>

                        <Subtitle>
                            {user.email}
                        </Subtitle>
                    </div>
                </div>
                <article className="mx-2">
                    <TabViewRoutes
                        basePath={`/profile`}
                        routes={[
                            { to: ``, label: 'Informasi', icon: { solid: props => <Icon.IconPhospor.IdentificationBadge {...props} weight="fill" />, outline: Icon.IconPhospor.IdentificationBadge }, element: ProfileInformationSubRoute },
                            { to: `/education`, label: 'Pendidikan', icon: { solid: props => <Icon.IconPhospor.GraduationCap {...props} weight="fill" />, outline: Icon.IconPhospor.GraduationCap }, element: ProfileEducationSubRoute },
                            { to: `/course`, label: 'Kursus', icon: { solid: props => <Icon.IconPhospor.Certificate {...props} weight="fill" />, outline: Icon.IconPhospor.GraduationCap }, element: ProfileCourseSubRoute },
                        ]}
                    />
                </article>
            </div>


        </MainLayout>
    )
}