import Input from "Common/UI/Component/Input";
import { useLoginStore } from "./LoginStore";
import { shallow } from 'zustand/shallow'
import { useCreateMyToken } from "Core/Authentication/API/MyTokenAPI";
import Button from "Common/UI/Component/Button";
import Logo from "Common/UI/Component/Logo";
import toast from "react-hot-toast"
import { useAuth } from "App";
import { transformErrorNetwork } from "Common/Utility/formatter";

export default function LoginRoute() {
  const auth = useAuth()
  const { email, password } = useLoginStore(state => ({ email: state.email, password: state.password }), shallow)
  const { changeEmail, changePassword } = useLoginStore(state => ({ changeEmail: state.changeEmail, changePassword: state.changePassword }))
  const createMyToken = useCreateMyToken({
    onSuccess: ({ data: response }) => {
      if (response.code === 200) {
        setTimeout(() => {
          auth.signIn(response.data.account, response.data.token)
        }, 1200)
      }
    },
    onError: (error) => {
      toast.error(transformErrorNetwork(error))
    }
  })

  const submit = (e) => {
    e.preventDefault()
    createMyToken.mutate({ id: email, password })
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Logo.DepartmentLogo className="mx-auto h-30 w-auto" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Masuk ke dalam sistem</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={submit} className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Surel (Surat Elektronik)
                </label>
                <div className="mt-1">
                  <Input.InputText value={email} onChange={changeEmail} className="w-full" />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Kata Sandi
                </label>
                <div className="mt-1">
                  <Input.InputSecure value={password} onChange={changePassword} className="w-full" />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <Button.LoadableButton type="submit" loading={createMyToken.isLoading} loadingText="Memproses Masuk.." className="w-full flex">
                  Masuk
                </Button.LoadableButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}