
import { useState, useEffect } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api"
import Geocode from "react-geocode";
import classNames from "classnames";

const defaultCenter = { lat: -6.3451408, lng: 106.7536356 };

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

export default function MapDisplay({ value, height = '800px', className, defaultZoom = 10.92 }) {
    const [center, setCenter] = useState(value ? { lat: value.latitude, lng: value.longitude } : defaultCenter)
    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY })

    useEffect(() => {
        if (value) {
            setTimeout(() => setCenter({ lat: value.latitude, lng: value.longitude }), 500)
        }
    }, [value])

    return (
        <div style={{ height: height }} className={classNames('relative flex w-full', className)}>
            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={{ width: '100%' }}
                    center={center}
                    zoom={defaultZoom}
                >
                    {value &&
                        <MarkerF
                            onLoad={marker => {
                            }}
                            position={{ lat: value.latitude, lng: value.longitude }}
                        />
                    }
                </GoogleMap>
            )}
        </div>
    )
}