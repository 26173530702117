import moment from "moment";
import { useEffect, useState } from "react";


const Simple = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    });

    function tick() {
        setDate(new Date());
    }

    const dateAsMoment = moment(date)

    const render = ({hour, minute, second}) => (
        <span className="countdown font-mono text-2xl">
            <span style={{ "--value": hour }}></span>:
            <span style={{ "--value": minute }}></span>:
            <span style={{ "--value": second }}></span>
        </span>
    )


    return render({hour: dateAsMoment.format('HH'), minute: dateAsMoment.format('mm'), second: dateAsMoment.format('ss')})
}

export default { Simple }