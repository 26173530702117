import MeetingRoutes from "Core/Appointment/UseCase/Route/Meeting/MeetingRoutes";
import MyCredentialRoutes from "Core/Authentication/UseCase/Route/MyCredential/MyCredentialRoutes";
import AttendanceRoutes from "Core/HumanResource/UseCase/Route/Attendance/AttendanceRoutes";
import { Route, Routes, Navigate } from "react-router-dom";

export default function AuthenticatedRouters() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/profile" />} />
            <Route path="/profile/*" element={<MyCredentialRoutes.ProfileRoute />} />
            <Route path="/reservation" element={<MeetingRoutes.BrowseMeetingRoute />} />
            <Route path="/attendance" element={<AttendanceRoutes.DashboardCalendarRoute />} />
        </Routes>
    )
}