import { Badge, Card, Metric, Text } from "@tremor/react";
import { TODAY, TODAY_MOMENT } from "Common/Constant/Date";
import { useHeadlessCalender } from "Common/UI/Block/CalenderView";
import Button from "Common/UI/Component/Button";
import EmptyState from "Common/UI/Component/EmptyState";
import Icon from "Common/UI/Component/Icon";
import Loader from "Common/UI/Component/Loader";
import MainLayout from "Common/UI/Layout/Main/MainLayout";
import { transformResponseToList } from "Common/Utility/formatter";
import { humanizeRelativeFromDate } from "Common/Utility/humanize";
import { useListMeetingByCalendarDate, useUpdateMeeting } from "Core/Appointment/API/MeetingAPI";
import classNames from "classnames";
import moment from "moment";
import { useSearchParams } from "react-router-dom";


export default function BrowseMeetingRoute() {
    const [searchParams, setSearchParams] = useSearchParams();
    const date = searchParams.get('date') || TODAY
    const { days, setCursorDate, cursorDate } = useHeadlessCalender({ initialCursorDate: TODAY_MOMENT })

    const browseMeeting = useListMeetingByCalendarDate({ date: date, expands: ['visitor', 'responseHistories'] })
    const meetings = transformResponseToList(browseMeeting.data)

    const updateMeeting = useUpdateMeeting({
        onSuccess: () => {
            browseMeeting.refetch()
        }
    })

    return (
        <MainLayout>
            <div className=" py-10 grid grid-cols-1 lg:grid-cols-4 mx-0 md:mx-4 gap-1.5 pb-24">
                <div className="col-span-1 lg:col-span-3">
                    <Card decoration="top" decorationColor="indigo">
                        <Text>Permintaan Audiensi</Text>
                        <Metric>{date === TODAY ? 'Hari ini' : moment(date, 'YYYY-MM-DD').format('dddd, D MMMM Y')}</Metric>

                        <div className="mt-12">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="hidden lg:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            ID
                                        </th>
                                        <th
                                            scope="col"
                                            className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                                        >
                                            Kebutuhan/Keperluan
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                        >
                                            Pengunjung
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                        >
                                            Nomor Pengunjung
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                        >
                                            Institusi Pengunjung
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Jam
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Aksi</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {browseMeeting.isLoading || meetings.length > 0 ? null : <EmptyState.Table colSpan={7} />}
                                    {!browseMeeting.isLoading ? null :
                                        <Loader.TableLoader rowSize={10} columnSize={6} />
                                    }
                                    {browseMeeting.isLoading ? null : meetings.map(meeting => (
                                        <tr key={meeting.ID}>
                                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                                {meeting.ID}

                                            </td>
                                            <td className="w-full max-w-0 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                {meeting.need}
                                                <dl className="font-normal lg:hidden">
                                                    <dt className="sr-only">Pengunjung</dt>
                                                    <dd className="mt-1 truncate text-gray-700">
                                                        {meeting.visitor.name}
                                                    </dd>
                                                    <dt className="sr-only sm:hidden">Telepon</dt>
                                                    <dd className="mt-1 truncate text-gray-500 sm:hidden">
                                                        {meeting.visitor.phoneNumber}
                                                    </dd>
                                                </dl>
                                            </td>
                                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                {meeting.visitor.name}
                                            </td>
                                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                {meeting.visitor.phone}
                                            </td>
                                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                {meeting.visitor.institution}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-gray-500 ">
                                                <div className="flex gap-1.5 items-center">
                                                    {moment(meeting.schedule.dateTime, 'YYYY-MM-DD HH:mm:ss').format('H:mm')}
                                                    <span className="hidden lg:block">
                                                        {moment(meeting.schedule.dateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') ? null :
                                                            <Badge size='xs' color="lime">
                                                                {humanizeRelativeFromDate(meeting.schedule.dateTime)}
                                                            </Badge>
                                                        }
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 ">
                                                <div className='inline-flex gap-1.5'>
                                                    <Button.LoadableButton loading={updateMeeting.isLoading && updateMeeting.variables?.ID === meeting.ID && updateMeeting.variables?.response === 1} loadingText='Menerima ...' onClick={() => updateMeeting.mutateAsync({ ID: meeting.ID, response: 1 })} size='xs' variant={meeting.response?.status === 1 ? 'primary' : 'secondary'} icon={Icon.IconPhospor.CheckCircle} color="sky">
                                                        <span className="hidden lg:block">Terima</span>
                                                    </Button.LoadableButton>

                                                    <Button.LoadableButton loading={updateMeeting.isLoading && updateMeeting.variables?.ID === meeting.ID && updateMeeting.variables?.response === 0} loadingText='Menolak ...' onClick={() => updateMeeting.mutateAsync({ ID: meeting.ID, response: 0 })} size='xs' variant={meeting.response?.status === 0 ? 'primary' : 'secondary'} icon={Icon.IconPhospor.XCircle} color="rose">
                                                        <span className="hidden lg:block">Tolak</span>
                                                    </Button.LoadableButton>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </Card>
                </div>
                <div>
                    <Card decoration="top" decorationColor="indigo">
                        <div className="flex items-center text-center text-gray-900">
                            <button
                                type="button"
                                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">Bulan sebelumnya</span>
                                <Icon.IconSolid.ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            <div className="flex-auto font-semibold">{moment(date, 'YYYY-MM-DD').format('MMMM Y')}</div>
                            <button
                                type="button"
                                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">BUlan selanjutnya</span>
                                <Icon.IconSolid.ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                            <div>S</div>
                            <div>S</div>
                            <div>R</div>
                            <div>K</div>
                            <div>J</div>
                            <div>S</div>
                            <div>M</div>
                        </div>
                        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                            {days.map((day, dayIdx) => (
                                <button
                                    onClick={() => setSearchParams({ date: day.date })}
                                    key={day.date}
                                    type="button"
                                    className={classNames(
                                        'py-1.5 hover:bg-gray-100 focus:z-10',
                                        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                        ((date === day.date) || (TODAY=== day.date)) && 'font-semibold',
                                        (date === day.date) && 'text-white',
                                        !(date === day.date) && day.isCurrentMonth && !(TODAY=== day.date) && 'text-gray-900',
                                        !(date === day.date) && !day.isCurrentMonth && !(TODAY=== day.date) && 'text-gray-400',
                                        (TODAY=== day.date) && !(date === day.date) && 'text-indigo-600',
                                        dayIdx === 0 && 'rounded-tl-lg',
                                        dayIdx === 6 && 'rounded-tr-lg',
                                        dayIdx === days.length - 7 && 'rounded-bl-lg',
                                        dayIdx === days.length - 1 && 'rounded-br-lg'
                                    )}
                                >
                                    <time
                                        dateTime={day.date}
                                        className={classNames(
                                            'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                                            (date !== day.date) && (TODAY=== day.date) && 'ring-1 ring-indigo-600',
                                            (date === day.date) && (TODAY=== day.date) && 'bg-indigo-600',
                                            (date === day.date) && !(TODAY=== day.date) && 'bg-gray-900'
                                        )}
                                    >
                                        {day.date.split('-').pop().replace(/^0/, '')}
                                    </time>
                                </button>
                            ))}
                        </div>
                    </Card>
                </div>
            </div>


        </MainLayout>
    )
}