import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useCalendarMeeting({ onSuccess, startDate, endDate }) {
    const path = `/appointment/appointment/meeting/calendar`
    const data = useQuery([...(path.split('/')), startDate, endDate], () => requestGet(path, { startDate, endDate }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useListMeetingByCalendarDate({ onSuccess, date, expands = [] }) {
    const path = `/appointment/appointment/meeting/calendar/${date}`
    const data = useQuery([...(path.split('/'))], () => requestGet(path, { expand: expands.join(',') }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useBrowseMeeting({ onSuccess, startDate, endDate, sort = 'datetime', order = 'asc', expands = [] }) {
    const path = `/appointment/appointment/meeting`
    const data = useQuery([...(path.split('/')), startDate, endDate, sort, order, expands], () => requestGet(path, { startDate, endDate, sort, order, expand: expands.join(',') }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUpdateMeeting({ onSuccess, onError }) {
    const mutation = useMutation(({ ID, response }) => requestPost(`/appointment/appointment/meeting/${ID}`, { response }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}