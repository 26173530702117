import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Badge, Button, Card, Metric, Text } from "@tremor/react";
import { classNames } from "primereact/utils";
import moment from "moment";
import { useAuth } from "App";
import MainLayout from "Common/UI/Layout/Main/MainLayout";
import CalenderView from "Common/UI/Block/CalenderView";
import Icon from "Common/UI/Component/Icon";
import { transformResponseToSingle } from "Common/Utility/formatter";
import Loading from "Common/UI/Component/Loading";
import { generateArray } from "Common/Utility/generator";
import Clock from "Common/UI/Component/Clock";
import { TODAY } from "Common/Constant/Date";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";
import { useCalendarAttendance, useCreateAttendance, useDateAttendance } from "Core/HumanResource/API/AttendanceAPI";
import { CreateAttendanceActivityDialog, useCreateAttendanceDialogStore } from "Core/HumanResource/UI/Attendance/CreateAttendanceActivityDialog";
import { useCalendarMeeting } from "Core/Appointment/API/MeetingAPI";
import { ListMeetingByDateDialog, useListMeetingByDateDialog } from "Core/Appointment/UI/Meeting/ListMeetingByDateDialog";
import { useGeolocated } from 'react-geolocated';
import MapDisplay from "../../../../../../Common/UI/Component/Map";
import { authenticationProvider } from "../../../../../../Common/Provider/Authentication";

export default function DashboardCalendarRoute() {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 5000,
    });
    const confirm = useConfirmDialogStore(state => state.confirm)
    const displayListMeetingByDate = useListMeetingByDateDialog(state => state.display)
    const confirmCreateAttendance = useCreateAttendanceDialogStore(state => state.confirm)
    const { user } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams();
    const period = searchParams.get('period')
    const momentPeriod = period ? moment(period, 'YYYY-MM') : moment()
    const startDate = momentPeriod.startOf('month').format('YYYY-MM-DD')
    const endDate = momentPeriod.endOf('month').format('YYYY-MM-DD')

    const todayDateAttendance = useDateAttendance({ date: TODAY })
    const todayAttendance = transformResponseToSingle(todayDateAttendance.data)

    const calenderAttendance = useCalendarAttendance({ startDate, endDate })
    const calenderAttendances = transformResponseToSingle(calenderAttendance.data)


    const calenderMeeting = useCalendarMeeting({ startDate, endDate })
    const calenderMeetings = transformResponseToSingle(calenderMeeting.data)

    const createAttendance = useCreateAttendance({
        onSuccess: () => {
            todayDateAttendance.refetch()
            calenderAttendance.refetch()
        }
    })

    const LocationInformation = () => (
        <div>
            {!isGeolocationAvailable ?
                <GeolocationNotSupport/> :
                !isGeolocationEnabled ?
                    <GeolocationNotEnabled/> :

                    coords ? (
                        <div className={"flex flex-col items-center justify-center "}>
                            Saat ini anda sedang berada di
                            <div className={"text-sm text-gray-400 "}>
                                {coords.latitude}, {coords.longitude}
                            </div>
                            <MapDisplay
                                height={null}
                                className="h-32 w-full object-cover lg:h-56 "
                                value={{ longitude: parseFloat(coords.longitude), latitude: parseFloat(coords.latitude) }}
                            />
                        </div>
                    ) : (
                        <div>Getting the location data&hellip; </div>
                    )}
        </div>
    )

    return (
        <MainLayout>
            <div className="m-10">
                <div className="grid grid-cols-4 gap-3 pb-24">
                    <div className="col-span-4 md:col-span-3">
                        <a href={`https://keuangan.mitrasdudi.id/api/en/human-resource/attendance/attendance/export/${period}?token=${authenticationProvider.token()}&requestAs=Employee`}>
                            <Button color="sky" size="xs" variant={"secondary"} a icon={Icon.IconPhospor.FileXls}>
                                Unduh Log Bulan
                            </Button>
                        </a>
                        <CalenderView
                            initialCursorDate={period ? moment(period, 'YYYY-MM-DD') : moment()}
                            onChangeDays={(days) => setSearchParams({ period: moment(days.find(item => item.isCurrentMonth).date, 'YYYY-MM-DD').format('YYYY-MM') })}
                            classItem={day => !calenderAttendance.isLoading && calenderAttendances[day.date]?.isHoliday ? 'bg-rose-100' : null}
                            renderItem={day => (
                                <div className="pt-3">
                                    {!day.isCurrentMonth ? null :
                                        calenderAttendance.isLoading || calenderMeeting.isLoading ? <Loading.Spinner size="20px"/> :
                                            calenderAttendances[day.date]?.isHoliday ?
                                                <Fragment>
                                                    {calenderAttendances[day.date]?.holidays.map(holiday => (
                                                        <Badge color="rose" size="xs" icon={Icon.IconPhospor.CalendarX}>
                                                            <span className=" hidden xl:block">{holiday.title}</span>
                                                        </Badge>
                                                    ))}
                                                </Fragment> :
                                                <Fragment>
                                                    <div className="flex gap-x-1.5 gap-y-1.5 w-full flex-wrap">
                                                        <Badge color={calenderAttendances[day.date]?.attendance?.clock.in ? 'lime' : 'gray'} size="xs" icon={Icon.IconPhospor.SignIn}>
                                                            <span className="hidden lg:block">Masuk</span>
                                                        </Badge>
                                                        <Badge color={calenderAttendances[day.date]?.attendance?.clock.out ? 'lime' : 'gray'} size="xs" icon={Icon.IconPhospor.SignOut}>
                                                            <span className="hidden lg:block">Keluar</span>
                                                        </Badge>
                                                    </div>
                                                    <div className="gap-2">
                                                        <button onClick={() => confirmCreateAttendance({ date: day.date })}>
                                                            <Badge color={calenderAttendances[day.date]?.attendance?.activities.length ? 'lime' : 'gray'} size="xs" icon={Icon.IconPhospor.Notepad}>
                                                                <span className="hidden lg:block">Log ({calenderAttendances[day.date]?.attendance?.activities.length || 0}/3)</span>
                                                            </Badge>
                                                        </button>

                                                        {calenderAttendances[day.date]?.duties.length === 0 ? null :
                                                            <Badge color="amber" size="xs" icon={Icon.IconPhospor.AirplaneTakeoff}>
                                                                <span className=" hidden xl:block">{calenderAttendances[day.date].duties.length} Dinas</span>
                                                            </Badge>
                                                        }
                                                    </div>
                                                    <div className="gap-2">
                                                        {calenderMeetings[day.date]?.meetings.length === 0 ? null :
                                                            <button onClick={() => displayListMeetingByDate({ date: day.date })}>
                                                                <Badge color="sky" size="xs" icon={Icon.IconPhospor.Users}>
                                                                    <span className=" hidden xl:block">{calenderMeetings[day.date].meetings.length} Pertemuan</span>
                                                                </Badge>
                                                            </button>
                                                        }
                                                    </div>
                                                </Fragment>
                                    }
                                </div>
                            )}
                            renderItemMobile={day => {
                                return (
                                    <button onClick={() => confirmCreateAttendance({ date: day.date })}>
                                        {!day.isCurrentMonth ? null :
                                            calenderAttendance.isLoading || calenderAttendance.isLoading ? <Loading.Spinner size="20px"/> :
                                                calenderAttendances[day.date]?.isHoliday ?
                                                    <Fragment>
                                                        <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                            {calenderAttendances[day.date]?.holidays.length === 0 ? null :
                                                                generateArray(calenderAttendances[day.date]?.holidays.length).map((_, index) =>
                                                                    <div key={index} className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", 'bg-rose-400')}/>
                                                                )
                                                            }
                                                        </div>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                            <div className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", calenderAttendances[day.date]?.attendance?.clock.in && calenderAttendances[day.date]?.attendance?.clock.out ? 'bg-lime-400' : 'bg-gray-400')}/>
                                                            <div className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", calenderAttendances[day.date]?.attendance?.activities.length ? 'bg-lime-400' : 'bg-gray-400')}/>
                                                            {calenderAttendances[day.date]?.duties.length === 0 ? null :
                                                                generateArray(calenderAttendances[day.date]?.duties.length).map((_, index) =>
                                                                    <div key={index} className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", 'bg-amber-400')}/>
                                                                )
                                                            }
                                                        </div>
                                                    </Fragment>
                                        }
                                    </button>
                                )
                            }}

                        />
                    </div>
                    <div className="col-span-4 md:col-span-1 mt-0 md:mt-28">
                        <Card>
                            <div className="-mt-20">
                                <div className="flex justify-center">
                                    <span className="inline-block relative ">
                                        <img
                                            className="h-32 w-32 rounded-full ring-2 ring-white drop-shadow-xl"
                                            src={user.avatarURL}
                                            alt=""
                                        />
                                        {(todayAttendance?.clock.in !== null && todayAttendance?.clock.out !== null) ?
                                            <span className="absolute top-1 right-1 block h-6 w-6 rounded-full ring-2 ring-white bg-green-400"/> :
                                            (todayAttendance?.clock.in && !todayAttendance?.clock.out) || (!todayAttendance?.clock.in && todayAttendance?.clock.out) ?
                                                <span className="absolute top-1 right-1 block h-6 w-6 rounded-full ring-2 ring-white bg-green-200 "/> :
                                                <span className="absolute top-1 right-1 block h-6 w-6 rounded-full ring-2 ring-white bg-gray-300"/>
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col items-center text-center mt-4">
                                <Text className="text-center">{user.role.employee.roleEntity.name}</Text>
                                <Metric textAlignment="text-center">{moment().format('dddd, D MMMM Y')}</Metric>
                                <Clock.Simple/>
                            </div>

                            <div className="flex flex-wrap justify-center gap-2 mt-5">
                                {todayAttendance === null || todayAttendance?.clock.in === null ?
                                    <Button
                                        variant="secondary"
                                        disabled={todayAttendance?.clock.in !== null}
                                        onClick={() => confirm({
                                            title: 'Masuk',
                                            message: 'Apakah anda yakin akan mencatat absesn masuk anda sekarang?',
                                            icon: Icon.IconPhospor.SignIn,
                                            iconColor: 'green',
                                            extraContent: <LocationInformation/>,
                                            onConfirm: () => createAttendance.mutateAsync({ date: TODAY, checkIn: true, ...(!coords ? {} : { inCoordinate: `${coords.latitude},${coords.longitude}` }) })
                                        })}
                                        color="lime" size="xs" icon={Icon.IconPhospor.SignIn}
                                        loading={createAttendance.isLoading || todayDateAttendance.isLoading} loadingText="Masuk ..."
                                    >
                                        Masuk
                                    </Button> :
                                    todayAttendance?.clock.out === null ?
                                        <Button
                                            variant="secondary"
                                            disabled={todayAttendance?.clock.out !== null}
                                            onClick={() => confirm({
                                                title: 'Keluar',
                                                message: 'Apakah anda yakin akan mencatat absesn keluar anda sekarang?',
                                                icon: Icon.IconPhospor.SignOut,
                                                iconColor: 'green',
                                                extraContent: <LocationInformation/>,
                                                onConfirm: () => createAttendance.mutateAsync({ date: TODAY, checkOut: true, ...(!coords ? {} : { outCoordinate: `${coords.latitude},${coords.longitude}` }) })
                                            })}
                                            color="lime" size="xs" icon={Icon.IconPhospor.SignIn}
                                            loading={createAttendance.isLoading || todayDateAttendance.isLoading} loadingText="Keluar ...">
                                            Keluar
                                        </Button> :
                                        <Button color="lime" disabled={true} size="xs" icon={Icon.IconPhospor.CheckCircle}>
                                            Sudah Masuk
                                        </Button>

                                }
                                <Button
                                    onClick={() => confirmCreateAttendance({ date: TODAY })}
                                    color="lime" size="xs" icon={Icon.IconPhospor.Notebook}
                                >
                                    Log
                                </Button>
                                <a href="http://absen.mitrasdudi.id/absent/dinas">
                                    <Button color="amber" size="xs" icon={Icon.IconPhospor.AirplaneTakeoff}>
                                        Dinas
                                    </Button>
                                </a>

                            </div>
                        </Card>

                    </div>
                </div>
            </div>
            <CreateAttendanceActivityDialog
                onConfirm={() => {
                    calenderAttendance.refetch()
                    todayDateAttendance.refetch()
                }}
            />

            <ListMeetingByDateDialog

            />
        </MainLayout>
    )
}


const GeolocationNotSupport = () => (
    <div>Your browser does not support Geolocation</div>
)
const GeolocationNotEnabled = () => (
    <div>Geolocation is not enabled</div>
)