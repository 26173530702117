import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "react-query"

export function useCalendarAttendance({ onSuccess, startDate, endDate }) {
    const path = `/human-resource/attendance/attendance/calendar`
    const data = useQuery([...(path.split('/')), startDate, endDate], () => requestGet(path, { startDate, endDate }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useDateAttendance({ onSuccess, date, expand }) {
    const path = `/human-resource/attendance/attendance/${date}`
    const data = useQuery([...(path.split('/')), expand], () => requestGet(path, { expand }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useCreateAttendance({ onSuccess, onError }) {
    const path = `/human-resource/attendance/attendance`
    const mutation = useMutation(
        ({ date, checkIn, checkOut, inCoordinate, outCoordinate, morningActivity, noonActivity, afterNoonActivity }) =>
            requestPost(path, {
                date, in: checkIn, out: checkOut,
                 inCoordinate, outCoordinate,
                'activity[1][description]': morningActivity,
                'activity[2][description]': noonActivity,
                'activity[3][description]': afterNoonActivity,
            }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}
